import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'productCategorySlice',
  initialState: {},
  reducers: {},
});

export const {} = slice.actions;
export default slice.reducer;
