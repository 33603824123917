import Cookies from 'js-cookie';
import { signInWithEmailAndPassword, auth } from '../../../services/FirebaseClient';

export async function login(email: string, password: string) {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  const token = await user?.getIdToken();
  if (token) {
    Cookies.set('token', token, {
      expires: 7,
    });
  }
  return user;
}
