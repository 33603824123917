/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain';
import { AsideMenuItem } from './AsideMenuItem';

import { useAppSelector } from '../../../../app/hooks/useRedux';

export function AsideMenuMain() {
  const intl = useIntl();

  const { menu } = useAppSelector((state) => state.auth);

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-bar-chart-line'
        bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        className='py-3'
      />

      {/* mapping menu */}
      {menu
        .filter((item: any) => item.isHide !== true)
        .map((val: any, idx: number) => {
          if (!val.hasAccess) return null;
          else if (!val.menus) {
            return (
              <AsideMenuItem
                key={idx}
                to={`/${val.to}`}
                title={val.title}
                fontIcon={val.icon}
                bsTitle={val.title}
                className='py-3'
              />
            );
          } else {
            return (
              <AsideMenuItemWithSubMain
                key={idx}
                to={`/${val.to}`}
                title={val.title}
                fontIcon={val.icon}
                bsTitle={val.title}
              >
                {val.menus?.map((child: any, idx2: number) => {
                  const find: any = menu.find((menuItem: any) => menuItem?.to === child?.to);

                  if (find?.hasAccess) {
                    return (
                      <AsideMenuItem
                        key={idx2}
                        to={child.to}
                        title={child.title}
                        hasBullet={true}
                        bsTitle={''}
                      />
                    );
                  } else return null;
                })}
              </AsideMenuItemWithSubMain>
            );
          }
        })}
    </>
  );
}
