// flag "isHide" for hide label from side menu, but need to declare to define routing

const MENU = [
  {
    id: 'order',
    to: 'apps/order',
    title: 'Manage Order',
    icon: 'bi-cart-check',
    access: ['ManageOrder'],
    hasAccess: true,
    isHide: false,
  },
  {
    id: 'customer',
    to: 'apps/customer',
    title: 'Manage Customer',
    icon: 'bi-person-lines-fill',
    access: ['ManageCustomer'],
    hasAccess: true,
    isHide: false,
  },
  {
    id: 'reseller',
    to: 'apps/reseller',
    title: 'Manage Reseller',
    icon: 'bi-person-workspace',
    access: ['ManageReseller'],
    hasAccess: true,
    isHide: false,
  },
  // {
  //   id: 'report',
  //   to: 'apps/report',
  //   title: 'Report',
  //   icon: 'bi-file-earmark-text',
  //   access: ['Report'],
  //   hasAccess: true,
  //   isHide: false,
  //   menus: [
  //     {
  //       to: 'apps/report/transaction',
  //       title: 'Transaction',
  //       access: ['Transaction'],
  //       hasAccess: true,
  //     },
  //   ],
  // },
  // {
  //   id: 'banner',
  //   to: 'apps/banner',
  //   title: 'Manage Banner',
  //   icon: 'bi-card-image',
  //   access: ['ManageBanner'],
  //   hasAccess: true,
  //   isHide: false,
  // },
  {
    id: 'banners',
    to: 'apps/',
    title: 'Manage Banner',
    icon: 'bi-card-image',
    access: ['ManageBanner'],
    hasAccess: true,
    isHide: false,
    menus: [
      {
        to: 'apps/banner',
        title: 'Banner',
        access: ['ManageBanner'],
        hasAccess: true,
      },
      {
        to: 'apps/banner-popup',
        title: 'Pop Up Banner',
        access: ['ManageBanner'],
        hasAccess: true,
      },
    ],
  },
  {
    id: 'highlight',
    to: 'apps/highlight',
    title: 'Manage Highlight',
    icon: 'bi-collection-play',
    access: ['ManageHighlight'],
    hasAccess: true,
    isHide: false,
  },
  {
    id: 'coupon',
    to: 'apps/coupon',
    title: 'Manage Coupon',
    icon: 'bi-ticket-perforated-fill',
    access: ['ManageCoupon'],
    hasAccess: true,
    isHide: false,
  },
  {
    id: 'social-media',
    to: 'apps/social-media',
    title: 'Manage Social Media Video',
    icon: 'bi-play-btn-fill',
    access: ['ManageHighlight'],
    hasAccess: true,
    isHide: false,
  },

  {
    id: 'feedback',
    to: 'apps/',
    title: 'Customer Feedback',
    icon: 'bi-chat-left-dots-fill',
    access: ['SearchUniqueKey', 'ManageCustomerFeedback', 'DownloadCustomerFeedback'],
    hasAccess: true,
    isHide: false,
    menus: [
      {
        to: 'apps/unique-key',
        title: 'Search',
        access: ['SearchUniqueKey'],
        hasAccess: true,
      },
      {
        to: 'apps/customer-feedback',
        title: 'List',
        access: ['ManageCustomerFeedback', 'DownloadCustomerFeedback'],
        hasAccess: true,
      },
    ],
  },
  {
    id: 'products',
    to: 'apps/',
    title: 'Manage Product',
    icon: 'bi-box-seam',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: false,
    menus: [
      {
        to: 'apps/product',
        title: 'Manage Product',
        access: ['ManageProduct'],
        hasAccess: true,
      },
      {
        to: 'apps/promotions',
        title: 'Promotions',
        access: ['ManageProduct'],
        hasAccess: true,
      },
      {
        to: 'apps/product-price',
        title: 'Manage Reseller Price',
        access: ['ManageProduct'],
        hasAccess: true,
      },
      {
        to: 'apps/product-category',
        title: 'Manage Product Category',
        access: ['ManageProduct'],
        hasAccess: true,
      },
      {
        to: 'apps/product-type',
        title: 'Manage Product Type',
        access: ['ManageProduct'],
        hasAccess: true,
      },
      {
        to: 'apps/label',
        title: 'Manage Label',
        access: ['ManageProduct'],
        hasAccess: true,
      },
    ],
  },
  {
    id: 'setting',
    to: 'apps/',
    title: 'Setting',
    icon: 'bi-gear',
    access: ['ManageUser', 'ManageRole', 'ManageAppSetting'],
    hasAccess: true,
    isHide: false,
    menus: [
      {
        to: 'apps/user',
        title: 'Manage User',
        access: ['ManageUser'],
        hasAccess: true,
      },
      {
        to: 'apps/role',
        title: 'Manage Permission',
        access: ['ManageRole'],
        hasAccess: true,
      },
      {
        to: 'apps/app-setting',
        title: 'Manage App Setting',
        access: ['ManageAppSetting'],
        hasAccess: true,
      },
    ],
  },
  // CAUTION
  // To add sub menu, please add the sub menu object below, so that the routing can access it.
  // Remember, you must change the 'isHide' to 'true'.
  {
    id: 'user',
    to: 'apps/user',
    title: 'Manage User',
    icon: 'bi-card-list',
    access: ['ManageUser'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'app-setting',
    to: 'apps/app-setting',
    title: 'Manage App Setting',
    icon: 'bi-database-fill-gear',
    access: ['ManageAppSetting'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'role',
    to: 'apps/role',
    title: 'Manage Permission',
    icon: 'bi-key',
    access: ['ManageRole'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'product-category',
    to: 'apps/product-category',
    title: 'Manage Product Category',
    icon: 'bi-tag',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'change-password',
    to: 'change-password',
    title: '',
    icon: '',
    access: [''],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'product',
    to: 'apps/product',
    title: 'Manage Product',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'create-product',
    to: 'apps/product/create',
    title: 'Manage Product',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'detail-product',
    to: 'apps/product/detail',
    title: 'Manage Product',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'product-type',
    to: 'apps/product-type',
    title: 'Manage Product Type',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'label',
    to: 'apps/label',
    title: 'Label',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'promotions',
    to: 'apps/promotions',
    title: 'Promotions',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'product-price',
    to: 'apps/product-price',
    title: 'Manage Reseller Price',
    icon: '',
    access: ['ManageProduct'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'customer-feedback',
    to: 'apps/customer-feedback',
    title: '',
    icon: '',
    access: ['DownloadCustomerFeedback', 'ManageCustomerFeedback'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'unique-key',
    to: 'apps/unique-key',
    title: '',
    icon: '',
    access: ['SearchUniqueKey'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'banner',
    to: 'apps/banner',
    title: '',
    icon: '',
    access: ['ManageBanner'],
    hasAccess: true,
    isHide: true,
  },
  {
    id: 'banner-popup',
    to: 'apps/banner-popup',
    title: '',
    icon: '',
    access: ['ManageBanner'],
    hasAccess: true,
    isHide: true,
  },
];

export default MENU;
