import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerRegionModel {
  pubId: string;
  name: string;
}

interface CustomerState {
  userId?: any;
  typeList?: any;
  listCountry?: CustomerRegionModel[];
  listProvince?: CustomerRegionModel[];
  listCity?: CustomerRegionModel[];
  listDistrict?: CustomerRegionModel[];
  listSubDistrict?: CustomerRegionModel[];
  listCustomerPriceType?: CustomerRegionModel[];
  updateSuccess?: boolean;
}

const emptyOption = [
  {
    pubId: '',
    name: 'Select option...',
  },
];

const customerState: CustomerState = {
  userId: '',
  typeList: '',
  listCountry: [...emptyOption],
  listProvince: [...emptyOption],
  listCity: [...emptyOption],
  listDistrict: [...emptyOption],
  listSubDistrict: [...emptyOption],
  listCustomerPriceType: [...emptyOption],
};

export const slice = createSlice({
  name: 'customerSlice',
  initialState: {
    ...customerState,
  },
  reducers: {
    setValueCustomerId(state, action: PayloadAction<CustomerState>) {
      state.userId = action.payload.userId;
    },
    setList(state, action: PayloadAction<CustomerState>) {
      const {
        typeList,
        listCountry,
        listCity,
        listProvince,
        listDistrict,
        listSubDistrict,
        listCustomerPriceType,
      } = action.payload;

      switch (typeList) {
        case 'listCountry':
          state.listCountry = listCountry?.concat(emptyOption);
          break;
        case 'listProvince':
          state.listProvince = listProvince?.concat(emptyOption);
          break;
        case 'listCity':
          state.listCity = listCity?.concat(emptyOption);
          break;
        case 'listDistrict':
          state.listDistrict = listDistrict?.concat(emptyOption);
          break;
        case 'listSubDistrict':
          state.listSubDistrict = listSubDistrict?.concat(emptyOption);
          break;
        case 'listCustomerPriceType':
          state.listCustomerPriceType = listCustomerPriceType?.concat(emptyOption);
          break;
        default:
          break;
      }
    },
    setUpdateSuccess(state, action: PayloadAction<CustomerState>) {
      state.updateSuccess = action.payload.updateSuccess;
    },
  },
});

export const { setValueCustomerId, setList, setUpdateSuccess } = slice.actions;
export default slice.reducer;
