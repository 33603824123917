import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TiktokAccessTokenRequest } from '../../types/socmed';

const tiktokState: TiktokAccessTokenRequest = {
  scopes: '',
  state: '',
  code: '',
};

export const slice = createSlice({
  name: 'socmedSlice',
  initialState: {
    ...tiktokState,
  },
  reducers: {
    setValueTiktok(state, action: PayloadAction<TiktokAccessTokenRequest>) {
      const tempState = action.payload;
      state.code = tempState.code;
      state.state = tempState.state;
      state.scopes = tempState.scopes;
    },
  },
});

export const { setValueTiktok } = slice.actions;
export default slice.reducer;
