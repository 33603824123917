import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductChangeState, ProductEventPriceModel } from '../../types/productEventPrice';

interface ProductEventPriceState {
  data: Partial<ProductEventPriceModel>;
  submitSuccess?: boolean;
  listProduct?: any[];
}

const initialState: ProductEventPriceState = {
  data: {},
  submitSuccess: false,
  listProduct: [],
};

export const slice = createSlice({
  name: 'productEventPriceSlice',
  initialState,
  reducers: {
    setDataList(state, action) {
      state.data = action.payload;
    },
    setSubmitSuccess(state, action) {
      state.submitSuccess = action.payload.submitSuccess;
    },
    setListProduct(state, action) {
      state.listProduct = action.payload;
    },
    onChangeListProduct(state, action: PayloadAction<ProductChangeState>) {
      const { value, index, defaultPrice } = action.payload;
      let tempState = state.listProduct || [];

      tempState[index].price = value;
      // flag for check inputValue same or not with defaultValue
      tempState[index].isUpdate = parseInt(value) === defaultPrice ? false : true;

      state.listProduct = tempState;
    },
  },
});

export const { setDataList, setSubmitSuccess, setListProduct, onChangeListProduct } = slice.actions;
export default slice.reducer;
