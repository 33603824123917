import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  submitSuccess?: boolean;
  approvalSuccess?: boolean;
}

const initialState: InitialState = {
  submitSuccess: false,
  approvalSuccess: false,
};

export const slice = createSlice({
  name: 'resellerSlice',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSubmitSuccess(state, action: PayloadAction<InitialState>) {
      state.submitSuccess = action.payload.submitSuccess;
    },
    setApprovalSuccess(state, action: PayloadAction<InitialState>) {
      state.approvalSuccess = action.payload.approvalSuccess;
    },
  },
});

export const { setSubmitSuccess, setApprovalSuccess } = slice.actions;
export default slice.reducer;
