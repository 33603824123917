import { createSlice } from '@reduxjs/toolkit';
import { BannerPopUpResponse } from '../../types/bannerPopUp';

interface BannerState {
  data: Partial<BannerPopUpResponse>;
  submitSuccess?: boolean;
}

const initialState: BannerState = {
  data: {},
  submitSuccess: false,
};

export const slice = createSlice({
  name: 'bannerPopUplice',
  initialState,
  reducers: {
    setBannerPopUpList(state, action) {
      state.data = action.payload;
    },
    setSubmitSuccess(state, action) {
      state.submitSuccess = action.payload.submitSuccess;
    },
  },
});

export const { setBannerPopUpList, setSubmitSuccess } = slice.actions;
export default slice.reducer;
