import API from '../utils/constanta';
import axiosInstance from '../utils/fetcher';

type ModelChangePassword = {
  password?: string;
  confirmPassword?: string;
  oldPassword?: string;
};

const getAccessAuth = async (token?: string) => {
  return await axiosInstance
    .get(API.user.access, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      return res.data?.data;
    })
    .catch((error: any) => {
      throw error;
    });
};

const changePassword = async (data: ModelChangePassword) => {
  return await axiosInstance.put(API.user.changePassword, data);
};

export { getAccessAuth, changePassword };
