import API from '../utils/constanta';
import fetch from '../utils/fetcher';

import { TiktokAccessTokenRequest, TiktokResponse, TiktokVideoResponse } from 'types/socmed';

export const getUrlTiktokLogin = async () => {
  try {
    const response = await fetch.get<TiktokResponse>(`${API.socmed.tiktok}/login`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getListTiktokVideo = async () => {
  try {
    const response = await fetch.get<TiktokVideoResponse>(API.socmed.tiktok);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postCreateAccessTokenTiktok = async (params: TiktokAccessTokenRequest) => {
  try {
    return await fetch.post(`${API.socmed.tiktok}/callback`, null, { params });
  } catch (error) {
    return error;
  }
};
