/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useAppDispatch } from '../../../hooks/useRedux';
import { useFormik } from 'formik';

import { login } from '../core/_requests';
import Cookies from 'js-cookie';
import { getAccessAuth } from '../../../services/Auth';
import { setUserAccess } from '../../../store/auth.reducer';
import { showLoading } from '../../../store/dashboard.reducer';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .max(50, 'Your maximum Length limit is 50')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Your password length must be at least 6.')
    .max(50, 'Your maximum Length limit is 50')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // loading button
      setLoading(true);

      // loading screen
      dispatch(showLoading({ isLoading: true }));
      try {
        await login(values.email, values.password);

        const tempToken = Cookies.get('token') || '';
        const resAuth = await getAccessAuth(tempToken);

        dispatch(setUserAccess({ access: resAuth }));
        localStorage.setItem('access', JSON.stringify(resAuth.accessRights));
        localStorage.setItem('role', resAuth.roleName || '');

        // redirect to dashboard
        if (resAuth?.roleName === 'Customer Feedback View Only') {
          window.location.href = '/apps/customer-feedback';
        } else window.location.href = '/dashboard';
      } catch (error: any) {
        setStatus(error?.response?.statusText || 'Sorry, you don`t have access to this website');
        setSubmitting(false);
        setLoading(false);
        Cookies.remove('token');
      }
      dispatch(showLoading({ isLoading: false }));
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
