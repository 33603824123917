/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

// image
import logoImage from '../../../assets/image/scarlett-logo.png'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none';
    return () => {};
  }, []);

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={logoImage}
            className='theme-light-show h-200px'
          ></img>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
