import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import Chart from 'react-apexcharts';
import { DateRangePicker, DateRange } from '@matharumanpreet00/react-daterange-picker';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import { format } from 'date-fns';
import ReactSelect from 'react-select';

import { PageTitle } from '../../../_metronic/layout/core';
import { KTCard } from '../../../_metronic/helpers';

// import services
import {
  getOrder,
  getProductWishlist,
  getProductCart,
  getCourierSelected,
  getPaymentMethodSelected,
  getBestSeller,
} from '../../services/dashboard';

const DashboardWrapper = () => {
  const intl = useIntl();
  const role = localStorage.getItem('role');

  const [paymentData, setPaymentData] = useState<any>([]);
  const [couriersData, setCouriersData] = useState<any>([]);

  const [PeriodOrderType, setPeriodOrderType] = useState('last7d');
  const [dateRangeOrder, setDateRangeOrder] = useState<{ startDate: string; endDate: string }>();
  const [PeriodWislistType, setPeriodWislistType] = useState('last7d');
  const [dateRangeWishlist, setDateRangeWishlist] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const [PeriodCartType, setPeriodCartType] = useState('last7d');
  const [dateRangeCart, setDateRangeCart] = useState<{ startDate: string; endDate: string }>();
  const [PeriodPaymentType, setPeriodPaymentType] = useState('last7d');
  const [dateRangePayment, setDateRangePayment] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const [PeriodCourierType, setPeriodCourierType] = useState('last7d');
  const [dateRangeCourier, setDateRangeCourier] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const [PeriodBestSellerType, setPeriodBestSellerType] = useState('all');
  const [dateRangeBestSeller, setDateRangeBestSeller] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const [customerType, setCustomerType] = useState('default');

  const customerTypeOptions = [
    // { label: 'Default', value: 'default' },
    { label: 'All', value: 'default' },
    { label: 'Customer', value: 'Customer' },
    { label: 'Reseller', value: 'Reseller' },
  ];

  const { data: responseOrder } = useQuery(
    [
      'dashboard-orderList',
      {
        PeriodType: PeriodOrderType,
        ...(PeriodOrderType === 'custom' && {
          StartDate: dateRangeOrder?.startDate,
          EndDate: dateRangeOrder?.endDate,
        }),
      },
    ],
    () => {
      return getOrder({
        PeriodType: PeriodOrderType,
        ...(PeriodOrderType === 'custom' && {
          StartDate: dateRangeOrder?.startDate,
          EndDate: dateRangeOrder?.endDate,
        }),
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );
  const { data: responseWishlist } = useQuery(
    [
      'dashboard-wishlist',
      {
        PeriodType: PeriodWislistType,
        ...(PeriodWislistType === 'custom' && {
          StartDate: dateRangeWishlist?.startDate,
          EndDate: dateRangeWishlist?.endDate,
        }),
      },
    ],
    () => {
      return getProductWishlist({
        PeriodType: PeriodWislistType,
        ...(PeriodWislistType === 'custom' && {
          StartDate: dateRangeWishlist?.startDate,
          EndDate: dateRangeWishlist?.endDate,
        }),
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );

  const { data: responseCart } = useQuery(
    [
      'dashboard-cart',
      {
        PeriodType: PeriodCartType,
        ...(PeriodCartType === 'custom' && {
          StartDate: dateRangeCart?.startDate,
          EndDate: dateRangeCart?.endDate,
        }),
      },
    ],
    () => {
      return getProductCart({
        PeriodType: PeriodCartType,
        ...(PeriodCartType === 'custom' && {
          StartDate: dateRangeCart?.startDate,
          EndDate: dateRangeCart?.endDate,
        }),
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );
  const { data: dataPaymentMethod, isFetching: isFetchingDataPayment } = useQuery(
    [
      'dashboard-paymentmethods',
      {
        PeriodType: PeriodPaymentType,
        ...(PeriodPaymentType === 'custom' && {
          StartDate: dateRangePayment?.startDate,
          EndDate: dateRangePayment?.endDate,
        }),
      },
    ],
    () => {
      return getPaymentMethodSelected({
        PeriodType: PeriodPaymentType,
        ...(PeriodPaymentType === 'custom' && {
          StartDate: dateRangePayment?.startDate,
          EndDate: dateRangePayment?.endDate,
        }),
      });
    },
    {
      onSuccess: (data: any) => {
        const chartConfg = {
          series: [] as number[],
          options: {
            chart: {
              type: 'pie',
            },
            labels: [] as string[],
            legend: {
              position: 'bottom',
            },
          },
        };

        data.data.paymentMethods.forEach((item: { name: string; total: number }) => {
          chartConfg.series.push(item.total);
          chartConfg.options.labels.push(item.name);
        });

        setPaymentData(chartConfg);
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );

  const { data: dataCourier, isFetching: isFetchingDataCourier } = useQuery(
    [
      'dashboard-corier',
      {
        PeriodType: PeriodCourierType,
        ...(PeriodCourierType === 'custom' && {
          StartDate: dateRangeCourier?.startDate,
          EndDate: dateRangeCourier?.endDate,
        }),
      },
    ],
    () => {
      return getCourierSelected({
        PeriodType: PeriodCourierType,
        ...(PeriodCourierType === 'custom' && {
          StartDate: dateRangeCourier?.startDate,
          EndDate: dateRangeCourier?.endDate,
        }),
      });
    },
    {
      onSuccess: (data: any) => {
        const chartConfg = {
          series: [] as number[],
          options: {
            chart: {
              type: 'pie',
            },
            labels: [] as string[],
            legend: {
              position: 'bottom',
            },
          },
        };

        data.data.couriers.forEach((item: { courier: string; total: number; services: string }) => {
          chartConfg.series.push(item.total);
          chartConfg.options.labels.push(`${item.courier}-${item.services}`);
        });
        setCouriersData(chartConfg);
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );

  const { data: dataBestSeller } = useQuery(
    [
      'dashboard-bestseller',
      {
        PeriodType: PeriodBestSellerType,
        ...(PeriodBestSellerType === 'custom' && {
          StartDate: dateRangeBestSeller?.startDate,
          EndDate: dateRangeBestSeller?.endDate,
        }),
        ...(customerType !== 'default' && { CustomerTypeCategory: customerType }),
      },
    ],
    () => {
      return getBestSeller({
        PeriodType: PeriodBestSellerType,
        ...(PeriodBestSellerType === 'custom' && {
          StartDate: dateRangeBestSeller?.startDate,
          EndDate: dateRangeBestSeller?.endDate,
        }),
        ...(customerType !== 'default' && { CustomerTypeCategory: customerType }),
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: role !== 'Customer Feedback View Only',
    },
  );

  const OrderItemStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    minWidth: 120,
    height: 100,
    border: '1px solid black',
    borderRadius: 5,
  };

  const OrderItemValue: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  };

  const WishlistItem: React.CSSProperties = {
    display: 'flex',
    gap: 5,
    marginTop: 5,
  };

  const handleFilter = (data: any, type: string) => {
    const typeOfData = typeof data === 'object' ? 'custom' : data;
    switch (type) {
      case 'order':
        setPeriodOrderType(typeOfData);
        if (typeof data === 'object') {
          setDateRangeOrder(data);
        }
        break;
      case 'wishlist':
        setPeriodWislistType(typeOfData);
        if (typeof data === 'object') {
          setDateRangeWishlist(data);
        }
        break;
      case 'cart':
        setPeriodCartType(typeOfData);
        if (typeof data === 'object') {
          setDateRangeCart(data);
        }
        break;
      case 'payment':
        setPeriodPaymentType(typeOfData);
        if (typeof data === 'object') {
          setDateRangePayment(data);
        }
        break;
      case 'courier':
        setPeriodCourierType(typeOfData);
        if (typeof data === 'object') {
          setDateRangeCourier(data);
        }
        break;
      case 'bestSeller':
        setPeriodBestSellerType(typeOfData);
        if (typeof data === 'object') {
          setDateRangeBestSeller(data);
        }
        break;
      default:
        break;
    }
  };

  if (role === 'Customer Feedback View Only') {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      </>
    );
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='container-fluid'>
        <div className='row '>
          <div className='col-lg-6'>
            <KTCard
              className='card mb-4'
              style={{
                height: '350px',
                overflow: 'auto',
              }}
            >
              <div
                className='d-flex justify-content-between mb-5 p-5 pb-2'
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}
              >
                <h3 className='fw-bolder mb-0'>Order</h3>
                <div
                  className={'d-flex align-items-center ms-1 ms-lg-3'}
                  id='kt_filter_order_menu_toggle'
                >
                  <DateRangeComponent onClick={(value) => handleFilter(value, 'order')} />
                </div>
              </div>
              <div
                className='card-body d-flex align-items-stretch mb-5 gap-3'
                style={{
                  flexWrap: 'wrap',
                }}
              >
                <div style={OrderItemStyle}>
                  <span>Unpaid</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.unpaid}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Ready to Ship</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.readyToShip}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Processed</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.processed}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Shipped</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.shipped}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Received</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.toConfirmReceived}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Completed</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.completed}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Canceled</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.canceled}</div>
                </div>
                <div style={OrderItemStyle}>
                  <span>Return</span>
                  <div style={OrderItemValue}>{responseOrder?.data?.toReturn}</div>
                </div>
              </div>
            </KTCard>
          </div>

          <div className='col-lg-3'>
            <KTCard
              className='card mb-4 '
              style={{
                height: '350px',
                overflow: 'auto',
              }}
            >
              <div
                className='d-flex justify-content-between mb-5 p-5 pb-2'
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}
              >
                <div id='wishlish-title'>
                  <h3 className='fw-bolder mb-0'>Wishlist</h3>
                  <p className='text-muted mt-1 fw-semibold fs-7'>Top Products</p>
                </div>
                <div className={'d-flex  ms-1 ms-lg-3'} id='kt_filter_wishlist_menu_toggle'>
                  <DateRangeComponent onClick={(value) => handleFilter(value, 'wishlist')} />
                </div>
              </div>

              <div className='mt-0' style={{ marginLeft: 5, marginRight: 5 }}>
                {responseWishlist?.data?.map((item: any) => (
                  <div style={WishlistItem} key={item.pubId}>
                    <div
                      style={{
                        width: 50,
                        marginRight: 10,
                      }}
                    >
                      <img src={item.path} alt='product' width='50px' />
                    </div>
                    <div>
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                        }}
                      >
                        {item.productType.name}
                      </p>
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.total}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </KTCard>
          </div>

          <div className='col-lg-3 '>
            <KTCard
              className='card mb-4'
              style={{
                height: '350px',
                overflow: 'auto',
              }}
            >
              <div
                className='d-flex justify-content-between mb-5 p-5 pb-2'
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}
              >
                <div id='cart-title'>
                  <h3 className='fw-bolder mb-0'>Cart</h3>
                  <p className='text-muted mt-1 fw-semibold fs-7'>Top Products</p>
                </div>
                <div className={'d-flex  ms-1 ms-lg-3'} id='kt_filter_cart_menu_toggle'>
                  <DateRangeComponent onClick={(value) => handleFilter(value, 'cart')} />
                </div>
              </div>

              <div className='mt-0' style={{ marginLeft: 5, marginRight: 5 }}>
                {responseCart?.data?.map((item: any) => (
                  <div style={WishlistItem} key={item.pubId}>
                    <div
                      style={{
                        width: 50,
                        marginRight: 10,
                      }}
                    >
                      <img src={item.path} alt='product' width='50px' />
                    </div>
                    <div>
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                        }}
                      >
                        {item.productType.name}
                      </p>
                    </div>
                    <div
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.total}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </KTCard>
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-lg-4 col-md-6'>
            <KTCard
              className='card mb-4 p-5'
              style={{
                minHeight: '450px',
              }}
            >
              <div className='d-flex  justify-content-between mb-5'>
                <div id='payment-title'>
                  <h3 className='fw-bolder mb-0'>Payment Methods</h3>
                  <p className='text-muted mt-1 fw-semibold fs-7'>
                    From {dataPaymentMethod?.data?.total || '-'} Orders
                  </p>
                </div>
                <div className={'d-flex  ms-1 ms-lg-3'} id='kt_filter_paymentmethod_menu_toggle'>
                  <DateRangeComponent onClick={(value) => handleFilter(value, 'payment')} />
                </div>
              </div>
              <div
                className='card-body'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {!isFetchingDataPayment ? (
                  <Chart
                    options={{
                      ...paymentData.options,
                      chart: {
                        ...paymentData.options.chart,
                        type: 'pie',
                      },
                      labels: paymentData.options.labels,
                    }}
                    series={paymentData.series}
                    type='pie'
                    width={400}
                  />
                ) : null}
              </div>
            </KTCard>
          </div>
          <div className='col-lg-4 col-md-6'>
            <KTCard
              className='card mb-4 p-5'
              style={{
                minHeight: '450px',
              }}
            >
              <div className='d-flex  justify-content-between mb-5'>
                <div id='payment-title'>
                  <h3 className='fw-bolder mb-0'>Couriers</h3>
                  <p className='text-muted mt-1 fw-semibold fs-7'>
                    From {dataCourier?.data.total || '-'} Orders
                  </p>
                </div>
                <div className={'d-flex  ms-1 ms-lg-3'} id='kt_filter_courier_toggle'>
                  <DateRangeComponent onClick={(value) => handleFilter(value, 'courier')} />
                </div>
              </div>
              <div
                className='card-body'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {!isFetchingDataCourier ? (
                  <Chart
                    options={{
                      ...couriersData.options,
                      chart: {
                        ...couriersData.options.chart,
                        type: 'pie',
                      },
                      labels: couriersData.options.labels,
                    }}
                    series={couriersData.series}
                    type='pie'
                    width={400}
                  />
                ) : null}
              </div>
            </KTCard>
          </div>
          <div className='col-lg-4'>
            <KTCard
              className='card mb-4 '
              style={{
                height: '455px',
              }}
            >
              <div
                className='d-flex justify-content-between mb-5 p-5 pb-2'
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                  marginBottom: '0 !important',
                }}
              >
                <div id='wishlish-title'>
                  <h3 className='fw-bolder mb-0'>Best Seller</h3>
                  <p className='text-muted mt-1 fw-semibold fs-7'>Top Products</p>
                </div>
                <div className={'d-flex  ms-1 ms-lg-3'}>
                  <div
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <ReactSelect
                      placeholder='All'
                      options={customerTypeOptions}
                      onChange={(e) => setCustomerType(e?.value || 'all')}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '20px',
                          width: '180px',
                        }),
                      }}
                    />
                  </div>
                  <div id='kt_filter_wishlist_menu_toggle'>
                    <DateRangeComponent onClick={(value) => handleFilter(value, 'bestSeller')} />
                  </div>
                </div>
              </div>

              <div className='mt-0' style={{ marginLeft: 5, marginRight: 5, overflow: 'auto' }}>
                {dataBestSeller?.data?.map((item: any) => (
                  <div style={WishlistItem} key={item.pubId}>
                    <div
                      style={{
                        width: 50,
                        marginRight: 10,
                      }}
                    >
                      <img src={item.path} alt='product' width='50px' />
                    </div>
                    <div>
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                        }}
                      >
                        {item.productType.name}
                      </p>
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <p
                        style={{
                          marginBottom: 1,
                          fontSize: 12,
                        }}
                      >
                        {item.total}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </KTCard>
          </div>
        </div>
      </div>
    </>
  );
};

interface FilterProps {
  onClick: (arg: any) => void;
  valueDate?: { startDate: string | Date; endDate: string | Date };
  isInput?: boolean;
}

export const DateRangeComponent = ({ onClick, isInput = false, valueDate }: FilterProps) => {
  const [open, setOpen] = useState(false);

  const [dateRange, setDateRange] = useState<{ label?: string } & Required<DateRange>>({
    startDate: valueDate?.startDate ? new Date(valueDate.startDate) : new Date(),
    endDate: valueDate?.endDate ? new Date(valueDate.endDate) : new Date(),
  });

  const [tempDateRange, setTempDateRange] = useState<{ label?: string } & Required<DateRange>>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleFilter = (date: { label?: string } & Required<DateRange>) => {
    switch (date?.label?.replace(/ /g, '')) {
      case '7Day':
        onClick('Last7d');
        break;
      case '14Day':
        onClick('Last14d');
        break;
      case '1Month':
        onClick('Last1m');
        break;
      case '3Month':
        onClick('Last3m');
        break;
      case 'All':
        onClick('All');
        break;
      default:
        onClick({
          startDate: format(new Date(date.startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(date.endDate), 'yyyy-MM-dd'),
        });
        break;
    }
    // setOpen(false);
  };

  return (
    <>
      {isInput ? (
        <>
          <InputGroup
            onClick={() => {
              setOpen(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <FormControl
              style={{ padding: '8px 12px', borderColor: 'hsl(0, 0%, 80%)' }}
              type='text'
              value={`${format(new Date(dateRange.startDate), 'dd/MMM/yyyy')} - ${format(
                new Date(dateRange.endDate),
                'dd/MMM/yyyy',
              )}`}
              readOnly
            />
          </InputGroup>
        </>
      ) : (
        <div className='cursor-pointer symbol' onClick={() => setOpen(true)}>
          <i className='bi bi-funnel' style={{ fontSize: '1.5rem', color: 'black' }} />
        </div>
      )}

      <Modal show={open} onHide={() => setOpen(false)} backdrop centered dialogClassName='mw-700px'>
        <DateRangePicker
          open={true}
          onChange={(range) => {
            setTempDateRange(range as { label?: string } & Required<DateRange>);
          }}
          initialDateRange={dateRange}
          definedRanges={[
            {
              label: 'All',
              startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
              endDate: new Date(),
            },
            {
              label: 'Today',
              startDate: new Date(),
              endDate: new Date(),
            },
            {
              label: '7 Day',
              startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
              endDate: new Date(),
            },
            {
              label: '14 Day',
              startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
              endDate: new Date(),
            },
            {
              label: '1 Month',
              startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
              endDate: new Date(),
            },
            {
              label: '3 Month',
              startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
              endDate: new Date(),
            },
          ]}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginTop: 10,
            marginBottom: 10,
            gap: 10,
            marginRight: 10,
          }}
        >
          <Button
            onClick={() => {
              handleFilter(tempDateRange);
              setDateRange(tempDateRange);
              setOpen(false);
            }}
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
};
export { DashboardWrapper };
