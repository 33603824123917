import { format, fromUnixTime, formatDistance } from 'date-fns';

// default: monas, jakarta pusat
const coordinateMaps = {
  lat: -6.174179474004891,
  lng: 106.82657058092518,
};

const Pagination = (totalData: number, pageActive: number) => {
  const calcPage: number = Math.ceil(totalData / 10);

  let links: any[] = [];
  for (let i = 1; i <= calcPage; i++) {
    links.push({
      url: `/?page=${i}`,
      label: `${i}`,
      active: pageActive === i,
      page: i,
    });
  }

  return links;
};

const ConvertParam = (qParams: string) => {
  const split = qParams.split('&');
  let tempArr: string[] = [];

  split.map((value: string) => {
    if (value.includes('param')) tempArr.push(value.replace('param_', ''));
    else tempArr.push(value);
  });
  return tempArr.join('&');
};

const FormatDate = (date: string) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

const FormatDateAddWeeks = (date: string = '', weeks: number) => {
  if (!date) return '';

  const convertDate = new Date(date);
  convertDate.setDate(convertDate?.getDate() + weeks * 7);
  return FormatDate(convertDate?.toISOString()?.split('T')?.[0] || '');
};

const regex = () => {
  const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const phone = /^(^\+62)(\d{3,4}-?){2}\d{3,4}$/g;

  return {
    email,
    phone,
  };
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const characterSplitUppercase = (word: string) => {
  return word.split(/(?=[A-Z])/)?.join(' ') || '';
};

const removeHttps = (url: string) => {
  return url.replace(/^https?:\/\//, '');
};

export const dateFormatter = (date: Date | string, dateFormat = 'dd-MM-yyyy'): string => {
  return format(new Date(date), dateFormat);
};

export const dateUnixFormatter = (
  timeStamp: any | string,
  dateFormat = 'dd-MM-yyyy',
  isFormatDistance: boolean = false,
) => {
  const temp = fromUnixTime(timeStamp);
  if (isFormatDistance) {
    let temps = formatDistance(temp, new Date(), { addSuffix: true });
    return temps || '';
  } else {
    return dateFormatter(temp, dateFormat);
  }
};

const formatterCurrency = (value: number, nation = 'id-ID', currency = 'IDR'): string => {
  return new Intl.NumberFormat(nation, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatterNumber = (value: number, decimal = 0): string => {
  return new Intl.NumberFormat('id-ID', {
    style: 'decimal',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(value);
};

const parserNumber = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

const formatInitialsName = (str: string) => {
  const stringArr = str.trim().split(/\s+/g);
  const charArr = stringArr.map((sa) => sa[0].toUpperCase());

  if (charArr.length <= 2) {
    return charArr.join('');
  }

  return [charArr[0], charArr[1]].join('');
};

const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();
};

const convertToChar = (value: string, isSlug: boolean = false) => {
  return value
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, isSlug ? '' : ' ')
    .replace(/[\s_-]+/g, isSlug ? '-' : ' ')
    .replace(/^-+|-+$/g, '');
};

// product event price
const parseStringNumber = (value: any) => {
  if (isNaN(parseInt(value || ''))) return 0;
  else if (typeof value === 'string') return parseInt(value.replace(/[.]+/g, ''), 10);
  else return value || 0;
};

const mappingListProductPrice = (productList: any, type: string) => {
  let dumpArr: any = [];

  if (type === 'body') {
    (productList || []).map((item: any) => {
      dumpArr.push({
        pubId: item?.pubId || '',
        productId: item?.product?.pubId || '',
        price: parseStringNumber(parserNumber(item?.price) || 0) || 0,
      });
    });
  } else {
    (productList || []).map((item: any) => {
      dumpArr.push({
        product: {
          pubId: item?.product?.pubId || item?.pubId || '',
          name: item?.product?.name || item?.name || '',
        },
        oldPrice: formatterNumber(item?.product?.price || item?.price || ''),
        price: formatterNumber(item?.price || 0),
        label: item?.product?.name || item?.name || '',
        value: item?.product?.pubId || item?.pubId || '',
        pubId: item?.pubId || '',
        recordStatus: item?.product?.recordStatus || '',
      });
    });
  }
  return dumpArr || [];
};

const generateSlug = (value: string) => {
  return value
    .replace(/[\s+%&#?<>]/g, '-')
    .replace(/\//g, '')
    .replace('\\', '')
    .toLowerCase();
};

const separateCoupon = (couponCode: string = '') => {
  let tempCouponCode: string[] = couponCode?.split(/[, ]/g) || [];

  if (tempCouponCode.length !== 0) return tempCouponCode.filter((code) => !!code) || [];
  else return [];
};

const totalDiscount = (allDiscount: number[]): number => {
  if (allDiscount.length > 0) {
    return allDiscount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
  return 0;
};

const npwpFormat = (value: string) => {
  value = value.replace(/[A-Za-z\W\s_]+/g, '');
  let split = 6;
  const dots = [];

  for (let i = 0, len = value.length; i < len; i += split) {
    split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
    dots.push(value.substring(i, i + split));
  }

  const temp = dots.join('.');
  return temp.length > 12 ? `${temp.substring(0, 12)}-${temp.substring(12, 19)}` : temp;
};

const validImageFile = (file: File) => {
  if (window.FileReader && window.Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataView = new DataView(reader.result as ArrayBuffer);
        if (dataView.byteLength < 4) {
          resolve(false);
        } else {
          const headerBytes = new Uint8Array(dataView.buffer, 0, 4);
          const headerHex = Array.from(headerBytes)
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');

          switch (headerHex.toLowerCase()) {
            case '89504e47':
            case 'ffd8ffe0':
            case 'ffd8ffe1':
            case 'ffd8ffe2':
            case 'ffd8ffe3':
            case 'ffd8ffe8':
            case '00000018':
              resolve(true);
              break;
            default:
              resolve(false);
              break;
          }
        }
      };

      reader.onerror = () => {
        resolve(false);
      };

      reader.readAsArrayBuffer(file.slice(0, 4));
    });
  } else {
    return false;
  }
};

const replaceAreaAddress = (areaName: string = '') => {
  const blacklist = ['kecamatan', 'kabupaten', 'daerah istimewa', 'daerah khusus ibukota'];

  // break the functioon
  if (!areaName) return '';

  const filter: string[] = blacklist.filter((item: string) =>
    areaName.toLowerCase().includes(item),
  );

  if (!!filter && filter.length > 0) {
    const replace = areaName.replace(filter[filter.length - 1], '').trim();

    return replace || '';
  } else {
    return areaName.toLowerCase() || '';
  }
};

const removeWhiteSpace = (value: string = '') => {
  return (value || '')?.replace(/\s+/g, ' ')?.trim();
};

const phoneNumberFormatter = (phoneNumber: string, withPrefix: boolean = true) => {
  if (phoneNumber.charAt(0) === '0') {
    return withPrefix ? `+62${phoneNumber.substring(1)}` : `62${phoneNumber.substring(1)}`;
  }

  if (phoneNumber.charAt(0) === '8') {
    return withPrefix ? `+62${phoneNumber}` : `62${phoneNumber}`;
  }

  if (phoneNumber.charAt(0) === '6' && phoneNumber.charAt(1) === '2') {
    return withPrefix ? `+${phoneNumber}` : `${phoneNumber}`;
  }

  if (phoneNumber.charAt(0) === '+') {
    return withPrefix ? `${phoneNumber}` : `${phoneNumber.substring(1)}`;
  }
};

const checkCharFromSpecialChat = (value: string) => {
  const validName = new RegExp(/^(?! )[a-zA-Z0-9 ]*$/);

  if (validName.test(value)) return value;
  return '';
};

export {
  Pagination,
  ConvertParam,
  FormatDate,
  FormatDateAddWeeks,
  regex,
  capitalizeFirstLetter,
  characterSplitUppercase,
  removeHttps,
  formatterCurrency,
  formatterNumber,
  parserNumber,
  parseStringNumber,
  formatInitialsName,
  numberInputOnWheelPreventChange,
  convertToChar,
  mappingListProductPrice,
  generateSlug,
  separateCoupon,
  totalDiscount,
  npwpFormat,
  validImageFile,
  coordinateMaps,
  replaceAreaAddress,
  removeWhiteSpace,
  phoneNumberFormatter,
  checkCharFromSpecialChat,
};
