import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/useRedux';
import BeatLoader from 'react-spinners/BeatLoader';

const ListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  };

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>;
};

const Loading = (props: any) => {
  const { isLoading } = useAppSelector((state) => state.dashboard);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  const styles = {
    width: '100%',
    minHeight: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const stylesLayer: React.CSSProperties = {
    background: 'white',
    width: '100%',
    minHeight: '100%',
    height: '9999px',
    opacity: '0.8',
    zIndex: 9999,
  };

  return (
    <>
      {isLoading ? (
        <>
          <div style={{ ...stylesLayer, position: 'absolute' }} />
          <div style={{ ...styles, position: 'absolute' }}>
            <BeatLoader color='#5014d0' />
          </div>
        </>
      ) : null}
      <div>{props.children}</div>
    </>
  );
};

export { ListLoading, Loading };
