import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MENU from '../utils/menu';

interface UserState {
  user: { email: string; displayName: string };
}

interface AccessState {
  access: {
    name: string;
    userType: string;
    roleName: string;
    accessRights: string[];
  };
}

interface MenuState {
  menu: object[];
}

const initialState: UserState = {
  user: { email: '', displayName: '' },
};

const initAccessState: AccessState = {
  access: {
    name: '',
    userType: '',
    roleName: '',
    accessRights: [],
  },
};

const initMenuState: MenuState = {
  menu: [],
};

// function for check accessright
function checkAccess(arr1: string[], arr2: string[], roleName: string) {
  // return arr1.map((acc: string) => {
  //   // by rolename
  //   if (arr1.find((item: string) => roleName === item)) return true;
  //   // by access right
  //   else if (arr2.find((item: string) => acc === item)) return true;
  //   return false;
  // });
  return arr1.map((acc: string) => arr1.includes(roleName) || arr2.includes(acc));
}

export const slice = createSlice({
  name: 'authSlice',
  initialState: {
    ...initialState,
    ...initAccessState,
    ...initMenuState,
  },
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload.user;
    },
    setUserAccess: (state, action: PayloadAction<AccessState>) => {
      const { accessRights, roleName } = action?.payload.access;

      const tempMenu: object[] = MENU.map((val) => ({
        ...val,
        hasAccess: checkAccess(val.access, accessRights, roleName).includes(true),
      }));

      state.access = action.payload.access;
      state.menu = tempMenu;

      // check pathname for redirect to error page
      const { pathname } = window.location;
      if (
        pathname !== '/dashboard' &&
        !pathname.includes('/detail') &&
        !pathname.includes('/form')
      ) {
        const find = tempMenu.find((menu: any) => `/${menu.to}` === pathname);
        if (!find) window.location.href = '/error/404';
      }
    },
  },
});

export const { setUser, setUserAccess } = slice.actions;
export default slice.reducer;
