import { createSlice } from '@reduxjs/toolkit';
import { BannerResponse } from '../../types/banner';

interface BannerState {
  data: Partial<BannerResponse>;
  submitSuccess?: boolean;
}

const initialState: BannerState = {
  data: {},
  submitSuccess: false,
};

export const slice = createSlice({
  name: 'bannerSlice',
  initialState,
  reducers: {
    setBannerList(state, action) {
      state.data = action.payload;
    },
    setSubmitSuccess(state, action) {
      state.submitSuccess = action.payload.submitSuccess;
    },
  },
});

export const { setBannerList, setSubmitSuccess } = slice.actions;
export default slice.reducer;
