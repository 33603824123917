import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './app/store';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-toastify/dist/ReactToastify.css';

import { AppRoutes } from './app/routing/AppRoutes';
import { Loading } from './app/modules/widgets/components/Loading';

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <Loading>
            <ToastContainer />
            <AppRoutes />
          </Loading>
        </Provider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  );
}
