import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { KTCard } from '../../../_metronic/helpers';
import { postCreateAccessTokenTiktok } from 'app/services/Socmed';
import { showToast } from '../../store/dashboard.reducer';
import { setValueTiktok } from '../../store/socmed.reducer';

import { useAppDispatch } from '../../hooks/useRedux';

const CallbackTiktok = () => {
  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const [status, setStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVideoList = async (params: any) => await onLoginTiktok(params);

    const params = {
      scopes: searchParams.get('scopes'),
      code: searchParams.get('code'),
      state: searchParams.get('state'),
    };

    dispatch(setValueTiktok(params));

    fetchVideoList(params);
  }, []);

  const onLoginTiktok = async (params: any) => {
    setIsLoading(true);
    try {
      await postCreateAccessTokenTiktok(params);

      setStatus('Success');
      dispatch(
        showToast({
          text: 'Connected Successfully',
          type: 'success',
        }),
      );

      setTimeout(() => {
        window.location.href = '/apps/social-media';
      }, 5000);
    } catch (error: any) {
      console.log('error : ', error?.code || null);
      console.log('error : ', error?.message || 'Terjadi Kesalahan');

      setStatus(error?.message);
      dispatch(showToast({ text: error?.message || 'Terjadi Kesalahan', type: 'error' }));
    }
    setIsLoading(false);
  };

  return (
    <>
      <KTCard className='p-3 flex gap-3'>
        <div className='container'>
          <div className='row'>
            <div className='col'>Platform</div>
            <div className='col'>: Tiktok</div>
          </div>
          <div className='row'>
            <div className='col'>Loading Callback</div>
            <div className='col'>: {isLoading ? 'Loading' : 'Finished'}</div>
          </div>
          <div className='row'>
            <div className='col'>Status Callback</div>
            <div className='col'>: {status || 'Tidak Diketahui'}</div>
          </div>
        </div>
      </KTCard>
    </>
  );
};

export default CallbackTiktok;
