import { createSlice } from '@reduxjs/toolkit';
import { HighlightResponse } from '../../types/highlight';

interface HighlightState {
  data: Partial<HighlightResponse>;
}

const initialState: HighlightState = {
  data: {},
};

export const slice = createSlice({
  name: 'highlightSlice',
  initialState,
  reducers: {
    setHighlightList(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setHighlightList } = slice.actions;
export default slice.reducer;
