import API from '../utils/constanta';
import fetcher from '../utils/fetcher';

export const getOrder = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.dashboard.order}/total-by-statuses`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductWishlist = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.dashboard.product}/top-10-in-wishlist`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductCart = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.dashboard.product}/top-10-in-cart`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCourierSelected = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.dashboard.courier}/total-selected`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentMethodSelected = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.dashboard.payment}/total-selected`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getBestSeller = async (params?: any) => {
  try {
    const res = await fetcher.get(`${API.bestSeller}`, {
      params,
    });

    return res?.data;
  } catch (error) {
    console.error(error);
  }
};
