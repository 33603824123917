import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductChangeState, ProductPriceModel } from '../../types/productPrice';
import { formatterNumber, parserNumber } from 'app/utils/commons';

interface ProductPriceState {
  data: Partial<ProductPriceModel>;
  submitSuccess?: boolean;
  listProduct?: any[];
}

const initialState: ProductPriceState = {
  data: {},
  submitSuccess: false,
  listProduct: [],
};

export const slice = createSlice({
  name: 'productPriceSlice',
  initialState,
  reducers: {
    setDataList(state, action) {
      state.data = action.payload;
    },
    setSubmitSuccess(state, action) {
      state.submitSuccess = action.payload.submitSuccess;
    },
    setListProduct(state, action) {
      state.listProduct = action.payload;
    },
    onChangeListProduct(state, action: PayloadAction<ProductChangeState>) {
      const { value, index, defaultPrice } = action.payload;
      let tempState = state.listProduct || [];

      const tempPrice = parseInt(parserNumber(value || ''));
      tempState[index].price = formatterNumber(tempPrice);

      // flag for check inputValue same or not with defaultValue
      tempState[index].isUpdate = parseInt(value) === defaultPrice ? false : true;

      state.listProduct = tempState;
    },
  },
});

export const { setDataList, setSubmitSuccess, setListProduct, onChangeListProduct } = slice.actions;
export default slice.reducer;
