import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ListModelState {
  pubId: string;
  name: string;
  recordStatus?: string;
}

interface ListState {
  listRole: ListModelState[];
}

const listState: ListState = {
  listRole: [
    {
      pubId: '',
      name: '',
    },
  ],
};

export const slice = createSlice({
  name: 'userSlice',
  initialState: {
    ...listState,
  },
  reducers: {
    renderList(state, action: PayloadAction<ListState>) {
      let tempArrRole: ListModelState[] = action.payload.listRole;
      tempArrRole.push({
        pubId: '',
        name: 'Select Role...',
      });

      state.listRole = tempArrRole;
    },
  },
});

export const { renderList } = slice.actions;
export default slice.reducer;
