import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast, ToastOptions } from 'react-toastify';
import axiosInstance from '../utils/fetcher';
interface ToastState {
  type: string;
  text: string;
  url?: string;
  pubId?: any;
}

interface InitialState {
  isLoading: boolean;
}

const initialState: InitialState = {
  isLoading: false,
};

const toastState: ToastState = {
  type: '',
  text: '',
  pubId: '',
  url: '',
};

const onUndoDelete = async (value: ToastState) => {
  await axiosInstance.patch(`admin/${value.url}/${value.pubId}/undo-delete`).then((res) => {
    window.location.reload();
    return res;
  });
};

export const slice = createSlice({
  name: 'dashboardSlice',
  initialState: {
    ...initialState,
    ...toastState,
  },
  reducers: {
    showToast(state, action: PayloadAction<ToastState>) {
      const tempState = action.payload;
      const options: ToastOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        theme: 'light',
      };

      switch (tempState.type) {
        case 'success':
          toast.success(tempState.text, options);
          break;
        case 'delete':
          const CustomToast = () => {
            return (
              <div>
                {tempState.text}&nbsp;
                {!!tempState.url && (
                  <>
                    ,
                    <a href='#' onClick={() => onUndoDelete(tempState)}>
                      Undo
                    </a>
                  </>
                )}
              </div>
            );
          };

          toast.success(CustomToast, options);
          break;
        case 'warning':
          toast.warn(tempState.text, options);
          break;
        case 'info':
          toast.info(tempState.text, options);
          break;
        case 'error':
          toast.error(tempState.text, options);
          break;
        default:
          toast(tempState.text, options);
          break;
      }
    },
    showLoading(state, action: PayloadAction<InitialState>) {
      state.isLoading = action.payload.isLoading;
    },
  },
});

export const { showToast, showLoading } = slice.actions;
export default slice.reducer;
